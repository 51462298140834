import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Summary from 'content/blog/en/vercel-like-paas-summary';
export const _frontmatter = {
  "title": "A Vercel-like PaaS beyond Jamstack with Kubernetes and GitOps, part III",
  "subtitle": "Applications and the Dockerfile",
  "cover": "k8s+gitlab.webp",
  "description": "This third part explains how to write Dockerfiles and set up applications\nto hande incoming connection when deployed to a Kubernetes cluster.\n",
  "meta": {
    "title": "A Vercel-like PaaS beyond Jamstack with Kubernetes and GitOps, part III",
    "description": "How to write Dockerfiles and set up applications to hande incoming connection"
  },
  "tags": ["Kubernetes", "GitOps", "DevOps", "Automation"],
  "publishedAt": "2022-03-08T10:00:00",
  "published": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`This article is the `}<strong parentName="p">{`third part`}</strong>{` of the `}<em parentName="p">{`A Vercel-like PaaS beyond Jamstack with Kubernetes and GitOps`}</em>{` series.`}</p>
    <Summary mdxType="Summary" />
    <hr></hr>
    <p><em parentName="p">{`In `}<a parentName="em" {...{
          "href": "/en/blog/build-paas-kubernetes-gitops-part1"
        }}>{`part I`}</a>{`, I've set up a
Kubernetes cluster with k0s.
Then in `}<a parentName="em" {...{
          "href": "/en/blog/build-paas-kubernetes-gitops-part2"
        }}>{`part II`}</a>{`, I've configured a
GitLab pipeline to build Docker images and deploy applications on this cluster.`}</em></p>
    <p><em parentName="p">{`Now I'm going to write the required `}<inlineCode parentName="em">{`Dockerfile`}</inlineCode>{` to build those Docker images.`}</em></p>
    <ol {...{
      "start": 0
    }}>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#0"
        }}>{`Introduction`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#1"
        }}>{`Applications must listen for incoming requests`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#2"
        }}>{`The Dockerfile`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#3"
        }}>{`The Docker `}<em parentName="a">{`build`}</em>{` command`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#4"
        }}>{`Next step`}</a></li>
    </ol>
    <hr></hr>
    <h2><a parentName="h2" {...{
        "href": "@0"
      }}>{`Introduction`}</a></h2>
    <p>{`Since the first stage of `}<a parentName="p" {...{
        "href": "/en/blog/build-paas-kubernetes-gitops-part2#3"
      }}>{`my GitLab pipeline`}</a>{`
is the `}<em parentName="p">{`package`}</em>{` stage, I'll start this third part by creating the `}<inlineCode parentName="p">{`Dockerfile`}</inlineCode>{` to
complete the stage and pass to the next `}<em parentName="p">{`deploy stage`}</em>{`.`}</p>
    <p>{`Before that, I must take a step aside and talk about some specifics about application
examples. This will give a full explanation of how every piece of the setup connects
to each other and to understand the functional scope of each part.`}</p>
    <p>{`As a reminder, for the purpose of this experiment I've created `}<a parentName="p" {...{
        "href": "https://gitlab.com/k0s-examples/nodejs"
      }}>{`Node.js`}</a>{`,
`}<a parentName="p" {...{
        "href": "https://gitlab.com/k0s-examples/php"
      }}>{`PHP`}</a>{`, `}<a parentName="p" {...{
        "href": "https://gitlab.com/k0s-examples/python"
      }}>{`Python`}</a>{`
and `}<a parentName="p" {...{
        "href": "https://gitlab.com/k0s-examples/ruby"
      }}>{`Ruby`}</a>{` web applications. These are the
applications I'll talk about in the next section.`}</p>
    <hr></hr>
    <h2><a parentName="h2" {...{
        "href": "@1"
      }}>{`1. Applications must listen for incoming requests`}</a></h2>
    <p>{`At the end of `}<a parentName="p" {...{
        "href": "/en/blog/build-paas-kubernetes-gitops-part1#5"
      }}>{`part I`}</a>{`, I've made
a brief description of how traffic flows from the client to the application:`}</p>
    <pre><code parentName="pre" {...{}}>{`✓ 1.client       DNS ok and 443/TCP port open
  ↓
✓ 2.host         k0s installed
  ↓
✓ 3.ingress      ingress-nginx installed
  ↓
  4.service
  ↓
  5.pod
  ↓
  6.container
  ↓
  7.application
`}</code></pre>
    <p>{`The last component at the end of this diagram, the `}<inlineCode parentName="p">{`7.application`}</inlineCode>{`, represents
not only the code inside the container but the process that is running this code,
and listens for incoming connections.`}</p>
    <p>{`To do so, every application must implement these two requirements:`}</p>
    <ul>
      <li parentName="ul">{`The application must listen on port `}<inlineCode parentName="li">{`3000/TCP`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`It must listen on `}<inlineCode parentName="li">{`0.0.0.0`}</inlineCode>{` instead of `}<inlineCode parentName="li">{`localhost`}</inlineCode>{`.`}</li>
    </ul>
    <p>{`The Node.js implementation is done like this in the
`}<a parentName="p" {...{
        "href": "https://gitlab.com/k0s-examples/nodejs/-/blob/master/app.js#L3"
      }}>{`app.js`}</a>{` file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const host = "0.0.0.0";
const port = 3000;

require("http")
  .createServer((req, res) => {
    ...
  })
  .listen(port, host, () => {
`}</code></pre>
    <p>{`Likewise, the Python implementation in the `}<a parentName="p" {...{
        "href": "https://gitlab.com/k0s-examples/python/-/blob/master/app.py"
      }}>{`app.py`}</a>{` file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`hostName = "0.0.0.0"
serverPort = 3000
...
if __name__ == "__main__":
    webServer = HTTPServer((hostName, serverPort), Server)
    ...
    try:
        webServer.serve_forever()
`}</code></pre>
    <p>{`The Ruby implementation in `}<a parentName="p" {...{
        "href": "https://gitlab.com/k0s-examples/ruby/-/blob/master/app.rb"
      }}>{`the app.rb`}</a>{` file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`server = TCPServer.new 3000
`}</code></pre>
    <p>{`For the PHP application, incoming connections are handled by the PHP command line
and its built-in web server, see in the `}<a parentName="p" {...{
        "href": "https://gitlab.com/k0s-examples/php/-/blob/master/Dockerfile"
      }}>{`Dockerfile`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-dockerfile"
      }}>{`CMD ["-S", "0.0.0.0:3000", "app.php"]
`}</code></pre>
    <p>{`In the real world, traffic is not necessarily handled this way and a dedicated web
server such as nginx might stand in front and act as a reverse proxy, forwarding
requests to an event-driven server, instead of a process-based server, that runs
the application code.`}</p>
    <p>{`For instance, requests are usually forwarded by an nginx or Apache server and
handled by a `}<a parentName="p" {...{
        "href": "https://www.php.net/manual/en/install.fpm.php"
      }}>{`php-fpm`}</a>{` server for
PHP, a `}<a parentName="p" {...{
        "href": "https://rubygems.org/gems/unicorn"
      }}>{`unicorn`}</a>{` server for Ruby, and
`}<a parentName="p" {...{
        "href": "https://gunicorn.org/"
      }}>{`gunicorn`}</a>{` for Python.`}</p>
    <p>{`Outside of the hardcoded port value, the Node.js implementation can be done this
way, though, because Node.js has a built-in event-driven webserver and Kubernetes
will act as the process manager, a task that is usually delegated to `}<a parentName="p" {...{
        "href": "https://pm2.keymetrics.io/"
      }}>{`PM2`}</a>{`.`}</p>
    <hr></hr>
    <h2><a parentName="h2" {...{
        "href": "@2"
      }}>{`2. The Dockerfile and the docker build command`}</a></h2>
    <p>{`Docker images are built in the `}<a parentName="p" {...{
        "href": "/en/blog/build-paas-kubernetes-gitops-part2#4"
      }}><em parentName="a">{`package`}</em></a>{`
stage of the pipeline with the following command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session"
      }}>{`$ docker build -t \${CI_REGISTRY_IMAGE}:\${CI_COMMIT_SHORT_SHA} \\
  --build-arg COMMIT_SHORT_HASH=\${CI_COMMIT_SHORT_SHA} .
`}</code></pre>
    <p>{`Dockerfiles are almost identical throughout repositories. Given the Node.js example,
the `}<inlineCode parentName="p">{`Dockerfile`}</inlineCode>{` contains the following instructions:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-dockerfile",
        "metastring": "lineNumbers",
        "lineNumbers": true
      }}>{`# define the base system
FROM node:16-slim

# read value of COMMIT_SHORT_HASH passed with --build-arg
ARG COMMIT_SHORT_HASH

# copy COMMIT_SHORT_HASH value to COMMIT variable
ENV COMMIT $COMMIT_SHORT_HASH

# copy the GitLab repository into the image
COPY . /src

# move the current working directory to repository root
WORKDIR /src

# define the default program executed when running the image
ENTRYPOINT [ "node" ]

# define arguments passed to the default program
CMD [ "app.js" ]
`}</code></pre>
    <p>{`There are a lot of things to explain here, but first:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The term `}<em parentName="p">{`build-time`}</em>{` refers to the moment the `}<inlineCode parentName="p">{`docker build`}</inlineCode>{` command is executed.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The term `}<em parentName="p">{`run-time`}</em>{` refers to the moment the `}<inlineCode parentName="p">{`docker run`}</inlineCode>{` command executed,
or when a container has been deployed to Kubernetes.`}</p>
      </li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "@2-1"
      }}>{`About `}<em parentName="a">{`build-time`}</em>{` variables`}</a></h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Variables are passed at `}<em parentName="p">{`build-time`}</em>{` with the `}<inlineCode parentName="p">{`--build-arg`}</inlineCode>{` flag`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`They can be read with the `}<inlineCode parentName="p">{`ARG`}</inlineCode>{` instruction as in the `}<inlineCode parentName="p">{`Dockerfile`}</inlineCode>{` at line 5.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`They are not persisted at `}<em parentName="strong">{`run-time`}</em></strong>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`To persist a `}<em parentName="p">{`build-time`}</em>{` variable at `}<em parentName="p">{`run-time`}</em>{`, its value must be copied to
another variable as it's done with the `}<inlineCode parentName="p">{`ENV`}</inlineCode>{` instruction in the `}<inlineCode parentName="p">{`Dockerfile`}</inlineCode>{` at
line 8.`}</p>
      </li>
    </ul>
    <p>{`The following command illustrates this behaviour by dumping all variables with the
`}<a parentName="p" {...{
        "href": "https://man7.org/linux/man-pages/man1/printenv.1.html"
      }}><inlineCode parentName="a">{`printenv`}</inlineCode></a>{` command,
`}<inlineCode parentName="p">{`COMMIT_SHORT_HASH`}</inlineCode>{` doesn't exist but `}<inlineCode parentName="p">{`COMMIT`}</inlineCode>{` does and contains the copied value:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "lines=5",
        "lines": "5"
      }}>{`$ docker run --entrypoint printenv \\
  \${CI_REGISTRY_IMAGE}:\${CI_COMMIT_SHORT_SHA}
PATH=/usr/local/sbin:/usr/local/bin:/usr/sbin:/usr/bin:/sbin:/bin
HOSTNAME=e63a3f3ff400
COMMIT=7c77eb36
NODE_VERSION=16.13.0
YARN_VERSION=1.22.15
HOME=/root
`}</code></pre>
    <h3><a parentName="h3" {...{
        "href": "@2-2"
      }}>{`About `}<em parentName="a">{`run-time`}</em>{` variables`}</a></h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Variables are passed at `}<em parentName="p">{`run-time`}</em>{` with the `}<inlineCode parentName="p">{`-e`}</inlineCode>{` flag.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`If the value was already set at `}<em parentName="p">{`build-time`}</em>{` with an `}<inlineCode parentName="p">{`ENV`}</inlineCode>{` instruction in the
`}<inlineCode parentName="p">{`Dockerfile`}</inlineCode>{`, it is overwritten.`}</p>
      </li>
    </ul>
    <p>{`In the following example, I'm overwriting with the `}<inlineCode parentName="p">{`-e`}</inlineCode>{` flag at `}<em parentName="p">{`run-time`}</em>{` the
`}<inlineCode parentName="p">{`COMMIT`}</inlineCode>{` variable that has been set at `}<em parentName="p">{`build-time`}</em>{` with the `}<inlineCode parentName="p">{`ENV`}</inlineCode>{` instruction at
line 8 of the `}<inlineCode parentName="p">{`Dockerfile`}</inlineCode>{`,`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "lines=2,6",
        "lines": "2,6"
      }}>{`$ docker run --entrypoint printenv \\
  -e COMMIT="A different value" \\
  \${CI_REGISTRY_IMAGE}:\${CI_COMMIT_SHORT_SHA}
PATH=/usr/local/sbin:/usr/local/bin:/usr/sbin:/usr/bin:/sbin:/bin
HOSTNAME=e63a3f3ff400
COMMIT=A different value
NODE_VERSION=16.13.0
YARN_VERSION=1.22.15
HOME=/root
`}</code></pre>
    <h3><a parentName="h3" {...{
        "href": "@2-3"
      }}>{`Regarding the `}<inlineCode parentName="a">{`FROM`}</inlineCode>{`, `}<inlineCode parentName="a">{`COPY`}</inlineCode>{` and `}<inlineCode parentName="a">{`RUN`}</inlineCode>{` instructions`}</a></h3>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`FROM`}</inlineCode>{`, `}<inlineCode parentName="p">{`COPY`}</inlineCode>{` and `}<inlineCode parentName="p">{`RUN`}</inlineCode>{` instructions cannot be at `}<em parentName="p">{`run-time`}</em>{` since
they exist only at `}<em parentName="p">{`build-time`}</em>{` to construct the image's file system that will be
mounted in the container at `}<em parentName="p">{`run-time`}</em>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Changing the content of an image can be done with the `}<a parentName="p" {...{
            "href": "https://docs.docker.com/engine/reference/commandline/commit/"
          }}><inlineCode parentName="a">{`docker commit`}</inlineCode></a>{`
command. I definitely don't recommend using this command, nor to create a workflow
that integrates such practice. Images should be reproducible, meaning they should
be built from a `}<inlineCode parentName="p">{`Dockerfile`}</inlineCode>{` only.`}</p>
      </li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "@2-4"
      }}>{`Regarding the `}<inlineCode parentName="a">{`WORKDIR`}</inlineCode>{`, `}<inlineCode parentName="a">{`ENTRYPOINT`}</inlineCode>{` and `}<inlineCode parentName="a">{`CMD`}</inlineCode>{` instructions`}</a></h3>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`WORKDIR`}</inlineCode>{` and `}<inlineCode parentName="p">{`ENTRYPOINT`}</inlineCode>{` can also be at `}<em parentName="p">{`run-time`}</em>{` with `}<inlineCode parentName="p">{`--workdir`}</inlineCode>{`
or `}<inlineCode parentName="p">{`-w`}</inlineCode>{`, and `}<inlineCode parentName="p">{`--entrypoint`}</inlineCode>{` flags respectively.`}</p>
        <p parentName="li">{`Though, it is unlikely to happen since an image is usually built to run the command
set in the entrypoint.`}</p>
        <p parentName="li">{`A valid case would be to switch the entrypoint from `}<inlineCode parentName="p">{`node`}</inlineCode>{` to `}<inlineCode parentName="p">{`npm`}</inlineCode>{` for instance.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`CMD`}</inlineCode>{` can also be at `}<em parentName="p">{`run-time`}</em>{`, and is more likely to be
to pass arguments to the application, when environmental variables cannot be used.`}</p>
        <p parentName="li">{`Another valid case to overwrite the `}<inlineCode parentName="p">{`CMD`}</inlineCode>{` instruction would be if the `}<inlineCode parentName="p">{`ENTRYPOINT`}</inlineCode>{`
instruction is also .`}</p>
      </li>
    </ul>
    <p>{`For instance, the following command will overwrite most instructions of the Dockerfile:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ docker run --rm \\
  -e COMMIT="a different value" \\ # overwrite line 8
  --workdir /home \\               # overwrite line 14
  --entrypoint sh \\               # overwrite line 17
  \${CI_REGISTRY_IMAGE}:\${CI_COMMIT_SHORT_SHA} \\
  -c "echo \\$COMMIT"              # overwrite line 20

`}</code></pre>
    <hr></hr>
    <h2><a parentName="h2" {...{
        "href": "@3"
      }}>{`3. The docker `}<em parentName="a">{`build`}</em>{` command`}</a></h2>
    <p>{`I use the `}<a parentName="p" {...{
        "href": "https://docs.gitlab.com/ee/ci/variables/predefined_variables.html"
      }}>{`shortened Git commit hash`}</a>{`
as the image tag to identify what code an image contains:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session"
      }}>{`$ docker build -t \${CI_REGISTRY_IMAGE}:\${CI_COMMIT_SHORT_SHA} .
`}</code></pre>
    <p>{`I also pass this value to the Docker `}<inlineCode parentName="p">{`build`}</inlineCode>{` command with the `}<inlineCode parentName="p">{`--build-arg`}</inlineCode>{` flag
so that I can copy it to an environmental variable as explained in the previous section:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session"
      }}>{`$ docker build -t \${CI_REGISTRY_IMAGE}:\${CI_COMMIT_SHORT_SHA} \\
  --build-arg COMMIT_SHORT_HASH=\${CI_COMMIT_SHORT_SHA} .

$ docker push \${CI_REGISTRY_IMAGE}:\${CI_COMMIT_SHORT_SHA}
`}</code></pre>
    <p>{`The video below shows the whole `}<em parentName="p">{`package`}</em>{` stage running:`}</p>
    <video src="package-stage.mp4" frame="box" />
    <hr></hr>
    <h2><a parentName="h2" {...{
        "href": "@4"
      }}>{`4. Next step`}</a></h2>
    <p>{`Images have been built and pushed to the `}<em parentName="p">{`Container Registry`}</em>{`. The last missing
configurations are `}<em parentName="p">{`Kubernetes manifests`}</em>{` to allow the `}<em parentName="p">{`deploy`}</em>{` stage to deploy
applications to the Kubernetes cluster with `}<inlineCode parentName="p">{`kubectl`}</inlineCode>{`.`}</p>
    <p><strong parentName="p">{`A Vercel-like PaaS beyond Jamstack with Kubernetes and GitOps, part IV: Kubernetes manifests`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      